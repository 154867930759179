export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"de","iso":"de-DE","name":"Deutsch","file":"de.json"},{"code":"es","iso":"es-ES","name":"Español","file":"es.json"},{"code":"fr","iso":"fr-FR","name":"Français","file":"fr.json"},{"code":"pt","iso":"pt-BR","name":"Português","file":"pt.json"},{"code":"en","iso":"en-US","name":"English","file":"en.json"},{"code":"it","iso":"it-IT","name":"Italiano","isCatchallLocale":true,"file":"it.json"}],
  defaultLocale: "it",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/usr/src/app/locales",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://cving.com",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {"radar":{"en":"/job-offers","it":"/offerte-lavoro","fr":"/offres-emploi","es":"/ofertas-empleo","pt":"/ofertas-emprego","de":"/job-angebote"},"radar/_city":{"en":"/job-offers/:city","it":"/offerte-lavoro/:city","fr":"/offres-emploi/:city","es":"/ofertas-empleo/:city","pt":"/ofertas-emprego/:city","de":"/job-angebote/:city"},"radar/_city/_slug":{"en":"/job-offers/:city/:slug","it":"/offerte-lavoro/:city/:slug","fr":"/offres-emploi/:city/:slug","es":"/ofertas-empleo/:city/:slug","pt":"/ofertas-emprego/:city/:slug","de":"/job-angebote/:city/:slug"},"dtw/_slug":{"en":false,"fr":false,"es":false,"pt":false,"de":false}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  fallbackLocale: "en",
  normalizedLocales: [{"code":"de","iso":"de-DE","name":"Deutsch","file":"de.json"},{"code":"es","iso":"es-ES","name":"Español","file":"es.json"},{"code":"fr","iso":"fr-FR","name":"Français","file":"fr.json"},{"code":"pt","iso":"pt-BR","name":"Português","file":"pt.json"},{"code":"en","iso":"en-US","name":"English","file":"en.json"},{"code":"it","iso":"it-IT","name":"Italiano","isCatchallLocale":true,"file":"it.json"}],
  localeCodes: ["de","es","fr","pt","en","it"],
}

export const localeMessages = {
  'de.json': () => import('../../locales/de.json' /* webpackChunkName: "lang-de.json" */),
  'es.json': () => import('../../locales/es.json' /* webpackChunkName: "lang-es.json" */),
  'fr.json': () => import('../../locales/fr.json' /* webpackChunkName: "lang-fr.json" */),
  'pt.json': () => import('../../locales/pt.json' /* webpackChunkName: "lang-pt.json" */),
  'en.json': () => import('../../locales/en.json' /* webpackChunkName: "lang-en.json" */),
  'it.json': () => import('../../locales/it.json' /* webpackChunkName: "lang-it.json" */),
}
