import Vue from 'vue'

import './fonts.css'

import { head } from '#booster/plugins/vFont/head'
import vFont from '#booster/plugins/vFont'
import FontList from '#booster/classes/FontList'
import { isSupportedBrowser } from '#booster/utils/browser';
import globals from '#booster/utils/globals';
import LoadingSpinner from '#booster/components/BoosterImage/classes/LoadingSpinner';

Vue.use(vFont)

const booster = Object.freeze({
  head: () => console.error('$booster.head() is not available in context'),
  crossorigin: "anonymous",
  isBrowserSupported: () => isSupportedBrowser({"regex":""}),
  loader: () => undefined,
  targetFormats: ["webp","jpg|jpeg|png|gif"]
});

const fontList = new FontList([{"family":"Bebas Neue","locals":["Bebas Neue"],"fallback":["sans-serif"],"variances":[{"style":"normal","weight":300,"sources":[{"src":"assets/fonts/BebasNeue-Light.woff2","type":"woff2"}],"src":require("assets/fonts/BebasNeue-Light.woff2"),"type":"woff2"},{"style":"normal","weight":400,"sources":[{"src":"assets/fonts/BebasNeue-Regular.woff2","type":"woff2"}],"src":require("assets/fonts/BebasNeue-Regular.woff2"),"type":"woff2"},{"style":"normal","weight":700,"sources":[{"src":"assets/fonts/BebasNeue-Bold.woff2","type":"woff2"}],"src":require("assets/fonts/BebasNeue-Bold.woff2"),"type":"woff2"}]},{"family":"Lato","locals":["Lato"],"fallback":["sans-serif"],"variances":[{"style":"normal","weight":300,"sources":[{"src":"assets/fonts/Lato-Light.woff2","type":"woff2"}],"src":require("assets/fonts/Lato-Light.woff2"),"type":"woff2"},{"style":"normal","weight":400,"sources":[{"src":"assets/fonts/Lato-Regular.woff2","type":"woff2"}],"src":require("assets/fonts/Lato-Regular.woff2"),"type":"woff2"},{"style":"normal","weight":700,"sources":[{"src":"assets/fonts/Lato-Bold.woff2","type":"woff2"}],"src":require("assets/fonts/Lato-Bold.woff2"),"type":"woff2"}]}]);

globals.getImageSize = async (src) => {
  const { width, height } =await new Promise((resolve) => {
    const img = new window.Image();
    img.onload = () => resolve({width: img.naturalWidth, height: img.naturalHeight});
    img.src = src;
  });

  return {width, height};
};

export default (context, inject) => {
  inject('getFont', fontList.getFont.bind(fontList));
  inject('booster', booster);

  // For each render an own critical font style map is generated, which is used initially during page loading.
  const criticalFontStyles = {};
  inject('addCriticalFontStyle', (style) => {
    criticalFontStyles[style.hid] = style;
  });
  if (process.static && process.server) {
    context.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._criticalFontStyles = criticalFontStyles
    })
  }
}

!('$booster' in Vue.prototype) && Object.defineProperty(Vue.prototype, '$booster', {
  get () {
    return Object.freeze(Object.assign({}, booster, {head: head.bind(this)}));
  }
});
